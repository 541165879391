
.navbar {
    background: $primary;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media(max-width: 760px) {
      width: auto
    }

    a {
      color: white;
      text-decoration: none;
    }
  
    img {
      height: 56px;
      margin-left: 7em;

      @media (max-width: 760px) {
        height: 32px;
        margin-left: 15em;
      }
    }
  
    &-content {
      display: flex;
      height: 70px;
      padding-right: 14em;
  
      &-route {
        display: flex;
        align-items: center;
        margin-right: 1em;
        height: 86.5%;
        text-align: center;
        transition: .4s;
        padding: 0.3em;
        cursor: pointer;

        @media(max-width: 760px) {
          font-size: 12px;
        }
  
        &:hover {
          // background-color: #9d5248
        }
      }
    }
  }