.login {
    background: #590000;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;

    p {
        // align-self: start;
    }


    img {
        margin-top: 1vh;
        margin-bottom: 2vh;
        height: 90px;
    }

    form {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 30%;
        height: auto;
        background: rgb(255, 255, 255);
        box-shadow: 0px 0px 5px rgb(62, 62, 62);
        border-radius: 12px;
        padding: 1em;
        padding-bottom: 3em;

        @media(max-width: 760px) {
            width: 80%;
        }

        input {
            width: 80%;
            height: 32px;
            margin-bottom: 2vh;
        }

        button {
            width: 81%;
            height: 42px;
            background: #3f91d3;
            color: white;
            transition: .4s;
            cursor: pointer;
            border: none;

            &:hover {
                background: $primary;
                color: white;
            }
        }
    }

    &-error {
        color: $primary;
    }

    &-forgot-password {
        align-self: baseline;
        margin-left: 50px;
        margin-bottom: 1em;
        margin-top: 0.5em;
        font-size: 10px;

        @media(max-width: 760px) {
            margin-left: 42px;
        }
    }
}