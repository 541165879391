.mapbox {
        th {
            height: 30px;
            font-size: 12px;

            &:not(:first-child) {
                background-color: rgb(77, 77, 77);
                color: white;
            }
        }

        .expanded:nth-child(even) {
            background: rgb(197, 197, 197);
        }

        td {
            color: black;
            text-align: center;
            font-size: 12px;
        }

        tr {
            cursor: pointer;
        }

        i {
            color: #3f91d3;
            font-size: 22px;
        }
        
        .line {
            background: rgb(124, 124, 124);
            height: 2px;
            width: 100%;
            margin-left: 1em;
            margin-right: 1em;
        }

        .open-orders-row-expansion {
            background: white;
        }

        .mapboxgl-ctrl-attrib {
            .mapboxgl-ctrl-attrib-button {
                background: white;
                border: none;
            }
        }

.mapboxgl-canvas-container {
    display: none;
  }
  
  .mapboxgl-canvas-container:nth-child(2) {
    display: block !important;
  }
  
  .mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  
  .mapboxgl-ctrl-attrib-inner {
    display: none;
  }
    
  .mapbox-tooltip {
    top: -43em; 
    position: absolute; 
    background: white; 
    width: 520px; 
    border-radius: 10px; 
    padding: 1em;
    display: flex;
    box-shadow: 0px 0px 3px rgb(86, 86, 86);
    justify-content: space-between;
  
    &-row {
      display: flex;
      flex-flow: column;
      align-items: left;
      text-align: left;
      margin-right: 6px;
  
      h2 {
        font-size: 12px;
        color: rgb(40, 40, 40);
        margin-bottom: 0;
      }
  
      p {
        font-size: 10px;
        color: rgb(85, 85, 85);
        margin-top: 0ch;
      }
    }
  }

}