@import './styles/colors/colors.scss';

body {
  color: black;
}

.roadOne {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.active {
  background: #932c20;
}

.barCompleted {
  background-color: #3f91d3;
  width: 80%;
}

// Nav Styles
@import './styles/components/navbar/navbar.scss';

// Home Styles
@import './styles/components/pages/home/home.scss';
@import './styles/components/pages/home/dateFilter.scss';
@import './styles/components/pages/home/openOrders.scss';

// Login
@import './styles/login/login.scss';

// Mapbox 
@import './styles/components/pages/mapbox/mapbox.scss'
