.home {
    padding: 1em;
    display: flex;
    flex-flow: column;
    width: 70%;

    .search {
        button {
            background-color: #7f1e13;
            color: white;
            cursor: pointer;
            height: 36px;
            margin-right: 3px;
            border: none;
            transition: .4s;

            &:hover {
                background: #bb4132;
            }
        }
        select {
            height: 35px;
            margin-right: 0.4em;

            @media (max-width: 760px) {
                margin-top: 1em;
                width: 147px;            
            }
        }
    }

    &-date-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 1em;
        font-size: 12px;
        width: auto !important;

        @media (max-width: 760px) {
            flex-flow: column;
            align-items: baseline;
        }

        &-cell {
            display: flex;
            flex-flow: column;
        }

        .react-datepicker-wrapper {
            width: auto !important;
        }

        .react-datepicker__input-container {
            width: auto !important;
        }

        input {
            height: 30px;
            margin-right: 0.3em;
            cursor: pointer;
        }
    }
}