.open-orders {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(max-width: 760px) {
        display: block;
        overflow-x: scroll;
    } 

    &-csv-export {
        background-color: #7f1e13;
        color: white;
        cursor: pointer;
        height: 36px;
        margin-right: 3px;
        border: none;
        transition: 0.4s;
        align-self: flex-end;

        @media (max-width: 760px) {
            margin-bottom: 20px;
            align-self: baseline;
        }
            
        &:hover {
            background: $primary;
            color: white;
        }
    }

    &-map-button {
        background-color: #3f91d3;
        color: white;
        cursor: pointer;
        height: 36px;
        margin-right: 3px;
        border: none;
        transition: 0.4s;
        align-self: flex-end;

        @media (max-width: 760px) {
            margin-bottom: 20px;
            align-self: baseline;
        }
            
        &:hover {
            background: $primary;
            color: white;
        }
    }

    table {
        border: solid 1px black;
        width: 100%;

        th {
            height: 30px;
            font-size: 12px;

            &:not(:first-child) {
                background-color: rgb(77, 77, 77);
                color: white;
            }
        }

        .expanded:nth-child(even) {
            background: rgb(197, 197, 197);
        }

        td {
            color: black;
            text-align: center;
            font-size: 12px;
        }

        tr {
            cursor: pointer;
        }

        i {
            color: #3f91d3;
            font-size: 22px;
        }
        
        .line {
            background: rgb(124, 124, 124);
            height: 2px;
            width: 100%;
            margin-left: 1em;
            margin-right: 1em;
        }

        // thead {
        //     th {
        //         background: white;
        //     }
        // }
    }

    &-pagination-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        select {
            border: none;
        }
    }

    &-row {
        background: $gray;
        height: 40px;

        &-expansion {
            background: $gray;
            height: 120px;
            padding: 2em;
            
            table i {
                color: #3f91d3;
                font-size: 22px;
            }

            &-row {
                display: flex;
                width: 80%;
            }

            &-column {
                display: flex;
                flex-flow: column;
                margin-right: .5em;
                text-align: initial;
                min-width: 18%;

                &-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5em;

                    b {
                        align-self: baseline;
                        margin-right: 0.5em;
                    }
                }
            }

            &-info {

                b {
                    font-size: 12px;
                }

                margin-right: 0.5em;
                font-size: 10px;
            }
        }
    }
}